import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
// import SEO from "../components/seo";
import PostItem from "../components/post-item";

function Posts({ data: { allContentfulPost } }) {
  return (
    <Layout>
      {/* <SEO title={title} description={subtitle} /> */}
      <div className="post-grid">
        {allContentfulPost.edges.map(({ node }, index) => {
          return (
            <PostItem
              key={index}
              title={node.title}
              slug={node.slug}
              author={node.author}
              fluid={node.image.fluid}
              createdAt={node.createdAt}
            />
          );
        })}
      </div>
    </Layout>
  );
}
export default Posts;
export const query = graphql`
  {
    allContentfulPost {
      totalCount
      edges {
        node {
          title
          subtitle
          slug
          author
          createdAt
          image {
            fluid {
              srcWebp
            }
          }
        }
      }
    }
  }
`;
